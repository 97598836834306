import React, {Component, Fragment} from 'react';
import Featureing from '../Featureing';
import Previews from '../Previews'
import Thumbnails from '../Thumbnails'
import NaviContainer from '../NaviContainer'

import Card from 'react-bootstrap/Card';
import '../publicSet.css'
import "../thumbnails.css"

import Videoscreen from "../Videoscreen"

// Google Analytics
import ReactGA from 'react-ga';

class PublicSet extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataSource: []
        }
        this.changeState = this.changeState.bind(this)
        this.getSetInfo = this.getSetInfo.bind(this)
    }

    componentDidMount() {
        let arry = []

        fetch('https://chicago-footage.net/json/cgf.json')
        .then((response) => response.json())
        .then (cgfData => {
            arry.push(cgfData)
            this.setState({dataSource: arry})
        })

        fetch('https://chicago-footage.net/json/cse.json')
        .then((response) => response.json())
        .then (cseData => {
            arry.push(cseData)
            this.setState({dataSource: arry})
        })

        fetch('https://chicago-footage.net/json/cgfw.json')
        .then((response) => response.json())
        .then ((cgfwData) => arry.push(cgfwData))

        fetch('https://chicago-footage.net/json/cz.json')
        .then((response) => response.json())
        .then (czData => {
            arry.push(czData)
            this.setState({dataSource: arry})
        })
    }

    getSetInfo (num, dataSourceIndex) {
        console.log(this.state.dataSource)
        let i = 0,
            index = dataSourceIndex;

            if (this.state.dataSource[index] === undefined) return false
            while (this.state.dataSource[index][i] && this.state.dataSource[index][i].Public_Set != num) {
                i++
            }
            if (this.state.dataSource[index][i] == undefined) {
                console.log('looking through next data set')
                index += 1;
                 return this.getSetInfo(num, index)
            } else {
                return this.state.dataSource[index][i]
            }        
    }

    changeState = (num)=> {
        this.setState({setData: this.getSetInfo(num, 0)})        
    }

    render() {

        //const TRACKING_ID = 'G-DM4W28MF1G'
        const TRACKING_ID = 'UA-39507870-1'
        ReactGA.initialize(TRACKING_ID)
        ReactGA.pageview(document.location.pathname);

        let SetNum = parseInt(window.location.href.split('=')[1]),
            setData = this.getSetInfo(SetNum, 0)
            console.log(setData)

        if (setData) {
            if (!setData.Ext) {
                return (
                    <Fragment>
                    <NaviContainer></NaviContainer>  
                    <Card bg="black" text="black" style={{width: '85%'}} className="test">
                        <h1 className='bg-dark-red tc'>Previews</h1>  
                        <div className='pl1'>
                        <Previews model={setData.Model} img={setData.image}
                        set={setData.Photo_Set} count={setData.Photo_Count}
                        preview1={setData.Preview1} preview2={setData.Preview2}
                        preview3={setData.Preview3} site={setData.Site}
                        ></Previews>
                        </div>
        
                        <h1 className='bg-dark-red tc'>{setData.Product_Name}</h1>   
                        <div className='tc'>
                        <Thumbnails model={setData.Model} img={setData.image}
                        set={setData.Photo_Set} count={setData.Photo_Count} 
                        site={setData.Site}
                        ></Thumbnails>
        
                        <h1 className='bg-dark-red tc'>Other Sets Featuring {setData.Model}</h1>    
                        <Featureing model={setData.Model} setNumber={setData.Public_Set}
                        changeState={this.changeState} site={setData.Site} 
                        ></Featureing>
    
                        </div>
                    </Card>
                    </Fragment>
                )
            } else {
                return (
                    <Fragment>
                        <NaviContainer></NaviContainer>
                        <Card bg="black" text="light" style={{width: '85%'}} className="test">
                        <Videoscreen title={setData.Product_Name}
                        jw_link={setData.jw_link} durration={setData.Clip_Length}
                        post_date={setData.Date_}
                        ></Videoscreen>
                        <h1 className='bg-dark-red tc'>Other Videos Featuring {setData.Model}</h1>
                        <Featureing model={setData.Model} setNumber={setData.Public_Set}
                        changeState={this.changeState} mediaType='video' site={setData.Site} 
                        title={setData.Product_Name} durration={setData.Clip_Length}
                        post_date={setData.Date_}
                        ></Featureing>
                        </Card>
                    </Fragment>
                )
            }
        } else {
            return(
                <Fragment>
                    <p>Loading...</p>
                </Fragment>
            )
        }

       
    }
}
export default PublicSet