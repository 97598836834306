import React, {Fragment, Component} from "react";

class Four04 extends Component {
    render() {
        return (
            <Fragment>
                <p>Page Not Found!!</p>
            </Fragment>
        )
    }
}
export default Four04