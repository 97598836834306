import React, {Component} from "react";
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css';

import Main from './Pages/Main';
import PublicSet from './Pages/PublicSet'
import Four04 from './Pages/Four04'
import Roster from './Pages/Roster'
import Page from './Pages/Page'
import Faq from "./Pages/Faq";
import MembersArea from "./Members/MembersArea";
import Set from './Members/Pages/Set'
import MainMem from './Members/Pages/MainMem'
import MemRoster from './Members/Pages/MembersRoster'
import MemPage from './Members/Pages/Page'
import MemTest from './Members/Pages/MemTest'
import Collabos from './Pages/Collabos'
import MemCollabos from './Members/Pages/Collabos'
import Test from './Test'

import { Route, Routes } from 'react-router-dom'

// Google Analytics
import ReactGA from 'react-ga';

class App extends Component {
  render() {

        //const TRACKING_ID = 'G-DM4W28MF1G'
        const TRACKING_ID = 'UA-39507870-1'
        ReactGA.initialize(TRACKING_ID)
        ReactGA.pageview(document.location.pathname);

    console.log('App')
    return (
      
    <div>
      <Routes>
      <Route path='/' element={<Main></Main>}></Route>
      <Route path='/publicset:setNumber' element={<PublicSet></PublicSet>}></Route>
      <Route path='/page:setNumber' element={<Page></Page>}></Route>
      <Route path='/roster' element={<Roster></Roster>}></Route>
      <Route path='/faq' element={<Faq></Faq>}></Route>
      <Route path="/collaborators" element={<Collabos></Collabos>}></Route>
      <Route path='/members_area' element={<MembersArea></MembersArea>}></Route>
      <Route path='/members_area/' element={<MainMem></MainMem>}></Route>
      <Route path='/members_area/set:setNumber' element={<Set></Set>}></Route>
      <Route path='/members_area/memroster' element={<MemRoster></MemRoster>}></Route>
      <Route path='/members_area/mempage:pageNumber' element={<MemPage></MemPage>}></Route>
      <Route path="/members_area/collaborators" element={<MemCollabos></MemCollabos>}></Route>
      <Route path='/test' element={<Test></Test>}></Route>
      <Route path='/members_area/memtest' element={<MemTest></MemTest>}></Route>
      <Route path='*' element={<Four04></Four04>}></Route>
      </Routes>
    </div>
     );
  }
}

export default App;
