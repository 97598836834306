import React, {Fragment, Component} from "react";
import { Link } from 'react-router-dom'

const CHI_API = 'https://chicago-footage.net/public_photos/sets/',
        CHI_API2 = 'https://chicago-footage.net/preview_thumbnails/'

class FeatureCards extends Component {
    constructor(props) {
        super(props)
        this.state = {
            setN: this.props.setNumber,
            img: this.props.img,

        }
        this.stateFunction = this.stateFunction.bind(this)
    }

    stateFunction = () =>{
        console.log(this.props)
        this.props.changeState(this.props.setNumber)

    }
    render() {
        //Adds 'jpg' to the end of pics from sources without the extention i.e Chi Soles Exposed.
        let imgSrc = ``
        if (this.props.img.split('.').length > 1) {
         imgSrc = `${CHI_API}${this.props.img}`
        } else {
            imgSrc = `${CHI_API}${this.props.img}.jpg`
        }
        let imgTextSplit = this.props.img.split('.'),
            getSetTitle = imgTextSplit[0].split('_'),
            setTitle = ''

            if (getSetTitle[1] !== undefined) {
                setTitle = `${getSetTitle[0]} ${getSetTitle[1]}`
            } else {
                setTitle = `${getSetTitle[0]}`
            }

            
        return (
            <div className="bg-dark-gray dib br3 grow pa3 ma2 bw2 shadow-5 tc white-70">
                <Link to={`/members_area/set=${this.props.setNumber}`}>
                <img className="updates" src={imgSrc}
                onClick={this.stateFunction}
                ></img>
                </Link>
                <h3>{setTitle}</h3>
            </div>
        )
    }
}
export default FeatureCards