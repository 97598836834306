import React, { Component} from "react";
import 'bootstrap/dist/css/bootstrap.min.css'

import Page from './Pages/Page'
import MemSet from './Pages/Set'
import Mainmem from './Pages/MainMem'
import MembersRoster from './Pages/MembersRoster'

import { Route, Routes } from 'react-router-dom'

class MembersArea extends Component {
    render() {
        console.log('Members')
        return (
            <div>
                <Routes>
                <Route path='/members_area/' element={<Mainmem></Mainmem>}></Route>
                <Route path='/members_area/page:pageNumber' element={<Page></Page>}></Route>
                <Route path='/members_area/set:setNumber' element={<MemSet></MemSet>}></Route>
                <Route path='/members_area/memroster' element={<MembersRoster></MembersRoster>}></Route>
                </Routes>
            </div>
        )
    }
}

export default MembersArea