import React, {Component} from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Data from '../JSON/nav_links.json';
import NaviLlinks from '../Components/NaviLinks';
import SearchBox from '../Components/SearchBox';

class NaviLinksRoster extends Component {
    static defaultProps = {
        Data
    }

    onSearchChange(event) {
        console.log(event.target.value)
    }

    render() {
        console.log(this.props)
        return (
            <Navbar bg='dark' variant='dark' expand='lg' sticky='top'>
                <Container>
                    <Navbar.Toggle aria-controls='basic-navbar-nav'></Navbar.Toggle>
                    <Navbar.Collapse id='basic-navbar-nav'>
                        <Navbar.Brand>Chi Guys</Navbar.Brand>
                        <Nav className='me-auto'>
                        {this.props.Data.map((link) => (
                            <NaviLlinks text={link.Label} url={link.Link}></NaviLlinks>
                        ))}
                        </Nav>
                        <SearchBox searchChange={this.props.onSearchChange}/>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        )
    }
}

export default NaviLinksRoster