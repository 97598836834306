import React, {Fragment, Component} from "react";
import { Link } from "react-router-dom";

const CHI_API = 'https://chicago-footage.net/preview_thumbnails/'

class Card extends Component {
    render() {
        let imgSrc = `${CHI_API}${this.props.profile_pic}.jpg`,
            pageNumber = this.props.pageNumber.split('=')[1]
    
        return(
            <Fragment>
            <div className="bg-dark-gray dib br3 grow pa3 ma2 bw2 shadow-5 tc white-70">
                <Link to={`/members_area/${this.props.page}=${pageNumber}`}>
                    <img className="ba white-70" alt="chiguy" src={imgSrc}></img>
                </Link>
                <h3 className="pa1">{this.props.name}</h3>
            </div>
            </Fragment>
        )
    }
}

export default Card