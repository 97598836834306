import React, {Fragment, Component} from "react";
import Card from 'react-bootstrap/Card';
import NaviContainer from '../NaviContainer'
import "../Css/main.css"

// Google Analytics
import ReactGA from 'react-ga';

class Faq extends Component {
    render() {

		//const TRACKING_ID = 'G-DM4W28MF1G'
        const TRACKING_ID = 'UA-39507870-1'
        ReactGA.initialize(TRACKING_ID)
        ReactGA.pageview(document.location.pathname);

        return (
            <Fragment>
            <NaviContainer></NaviContainer>
            <Card bg="black" text="light" style={{width: '80%'}} className="test">
                <Card.Body>
                <h1 class="tc "> Frequently Asked Questions </h1>
                <h3 className="question">Q) What happened to the other Chicago Footage Sites?</h3> 
		       		<p className="answer">A) Chi-guysfeet, Chi-soles-exposed, Chi Guys Footwork, and Chi Zillas have all been combine under 1 site Chicago Footage.</p> 
		       <h3 className="question">Q) What happened to all the old content?</h3> 
					<p className="answer"> A) All content featured on the previous 4 sites can be found on the current site, all in one place for each model.</p>
		       <h3 className="question">Q) Are the videos downloadable?</h3> 
					<p className="answer">A) All of the videos featured in the sites members area are downloadable.</p>
		       <h3 className="question">Q) I forgot my Username and Password, can you send me this information?</h3> 
					<p className="answer">A) Lost user login information can be retrieved at support.ccbill.com.</p>
			   <h3 className="question">Q) What payment processor is used for purchases in the Store?</h3>
					<p className="answer">A) PayPal is the processor used for all forms of payment in the Chicago Footage Store.</p>
		       <h3 className="question">Q) How are digital purchases from the store delivered?</h3> 
					<p className="answer"> A) Videos and picture sets purchased from the Chicago Footage Store will be sent to the buyer instantly via download link. The download link will be sent to the buyers email registered on file with PayPal. The download expires 72 hours after being sent and will not be re-sent. Buyers who make purchases via check through PayPal will receive the download link after the payment clears. </p>
		       <h3 className="question">Q) Can PayPal be used to purchase a membership?</h3>
					<p className="answer">A) CCBill is the only payment processor for website subscriptions</p>
		       <h3 className="question">Q) How do I cancel my membership?</h3>
					<p className="answer">A) To cancel your membership go to: support.ccbill.com.</p>
                </Card.Body>
            </Card>
            </Fragment>
        )
    }
}
export default Faq