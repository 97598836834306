import React, {Fragment, Component} from "react";
//Featureing
import NaviContainer from "../NaviContainer";
import Card from "react-bootstrap/Card";
import Colodex from "../Colodex";

// Google Analytics
import ReactGA from 'react-ga';

class Collabos extends Component {
    constructor() {
        super()
        this.state = {
            chiGuy: [],
            searchfield: ''
        }
    }

    componentDidMount () {
        fetch('https://chicago-footage.net/json/roster_info.json')
        .then(response => {
            return response.json()
        })
        .then (rosterData => {
            console.log(rosterData)
            this.setState({chiGuy: rosterData})
        });
        
    }

    onSearchChange = (event) => {
        this.setState({searchfield: event.target.value})
    }

    render() {

        //const TRACKING_ID = 'G-DM4W28MF1G'
        const TRACKING_ID = 'UA-39507870-1'
        ReactGA.initialize(TRACKING_ID)
        ReactGA.pageview(document.location.pathname);

        const filterchiGuys = this.state.chiGuy.filter(chiGuy => {
            return chiGuy.Model_Name.toLowerCase().includes(this.state.searchfield.toLowerCase())
        })
        return (
            <div className="tc">
            <NaviContainer></NaviContainer>
            <h3 black-025>Chicago Footage Collaborators</h3>
            <Colodex rosterData={filterchiGuys}></Colodex>
            </div>
        )
    }

}

export default Collabos