import React, {Component, Fragment} from 'react'
import FeatureCards from '../Components/FeatureCards'
import Data from '../../JSON/cgf.json'
import cseData from '../../JSON/cse.json'
import cgfwData from '../../JSON/cgfw.json'
import czData from '../../JSON/cz.json'

class Featuring extends Component {
    constructor() {
        super()
        this.state = {
            'Chi Guys Feet': Data,
            'Chi Soles Exposed': cseData,
            'Chi Guys Footwork': cgfwData,
            'Chi Zillas': czData
        }
        this.modelExists = this.modelExists.bind(this)
        this.findSets = this.findSets.bind(this)
    }

    componentDidMount () {
        fetch('https://chicago-footage.net/json/cgf.json')
        .then((response) => response.json())
        .then ((cgfData) => this.setState({'Chi Guys Feet': cgfData}));

        fetch('https://chicago-footage.net/json/cse.json')
        .then((response) => response.json())
        .then ((cseData) => this.setState({'Chi Soles Exposed': cseData}));

        fetch('https://chicago-footage.net/json/cgfw.json')
        .then((response) => response.json())
        .then ((cgfwData) => this.setState({'Chi Guys Footwork': cgfwData}))

        fetch('https://chicago-footage.net/json/cz.json')
        .then((response) => response.json())
        .then ((czData) => this.setState({'Chi Zillas': czData}));
    }

    modelExists(model, site) {
        
        for (let i = 0; i < this.state[site].length; i++) {
            if (model === this.state[site][i].Model) {
                console.log(`${model}, ${site}`)
                return this.findSets(model, site)
            }
        }
        return false
    }

    findSets(model, site) { //model name and site selection
        
        let foundSets = []
        this.state[site].map((set) => {
            if (set.Model === model) {
                if (set.Public_Set !== this.props.setNumber) {
                    foundSets.push(set)
                }
            }
        })
/*         for (let i=l; i<=r; i++){
            if (this.state[site][i].Public_Set !== this.props.setNumber){ //Removes current page from feature selection
                foundSets.push(this.state[site][i])
            }
        } */
    return foundSets
    }

    render() {
        if (!this.props.model) return false
        let sets = this.modelExists(this.props.model, this.props.site)
        if(!sets.length) {
            return false
        }
        console.log(this.props)
        return (
            <Fragment>
            <div className="Mediadex-cards">
                {sets.map((set) => (
                    <FeatureCards img={set.image} setNumber={set.Public_Set}
                    changeState={this.props.changeState}
                    ></FeatureCards>
                ))}
            </div>
            </Fragment>
        )
    }
}

export default Featuring