import Mediadex from '../Mediadex'
import 'bootstrap/dist/css/bootstrap.min.css'
import Card from 'react-bootstrap/Card';
import NaviContainer from '../NaviContainer';
import Roundabout from '../Roundabout';

// Google Analytics
import ReactGA from 'react-ga';

function Main(props) {
    console.log(props)
    //const TRACKING_ID = 'G-DM4W28MF1G'
    const TRACKING_ID = 'UA-39507870-1'
    ReactGA.initialize(TRACKING_ID)
    ReactGA.pageview(document.location.pathname);
  return (
    <div className="App">
      <NaviContainer></NaviContainer>
      <br />
      <Card bg="black" text="black" style={{width: '85%'}} className="test"> 
      <Roundabout></Roundabout>
      <Mediadex></Mediadex>
      </Card>
    </div>
  );
}

export default Main;
