import React, {Component, Fragment} from 'react'
import Mediadex from '../Components/Mediadex'
import Card from 'react-bootstrap/Card';
import Roundabout from '../../Roundabout';
import NaviContainer from '../Components/NaviContainer';

// Google Analytics
import ReactGA from 'react-ga';

class MainMem extends Component {
    render() {

        //const TRACKING_ID = 'G-DM4W28MF1G'
        const TRACKING_ID = 'UA-39507870-1'
        ReactGA.initialize(TRACKING_ID)
        ReactGA.pageview(document.location.pathname);

        console.log('Members Area')
        return (
            <Fragment>
            <NaviContainer></NaviContainer>
            <br />
            <Card bg="black" text="black" style={{width: '85%'}} className="test"> 
            <Roundabout></Roundabout>
            <Mediadex></Mediadex>
            </Card>
            </Fragment>
        )
    }
}

export default MainMem