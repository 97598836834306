import React, {Component, Fragment} from "react";

const CHI_Link = 'https://chicago-footage.net/public_photos/'

class Thumbnails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            count: this.props.count,
            model: this.props.model,
            set: this.props.set,
        }
    }
    fillArray() {
        let count = parseInt(this.props.count)
        let array = [...Array(count).keys()]
        return array;
    }

    render () {
        console.log(this.props)
        let previewThumbnails = [];        
            for(let photo =1; photo <= this.props.count; photo++) {
                let string = photo.toString()
                let sequence = string.padStart(this.props.count.length, '0')
                if (this.props.site == "Chi Guys Feet") {
                    previewThumbnails.push(`${CHI_Link}${this.props.model}/thumbnails/${this.props.model}${this.props.set}${sequence}_thumbnail.jpg`)
                } else {
                    previewThumbnails.push(`${CHI_Link}${this.props.model}/thumbnails/cz/${this.props.model}${this.props.set}${sequence}_thumbnail.jpg`)
                }
            }
        return (
            <div className="thumbnail_container">
                
                {previewThumbnails.map((photo) => (
                    <img className="generated" alt="" src={photo} key={photo}></img>
                ))}
            </div>
        )
    }
}

export default Thumbnails