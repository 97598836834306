import React, {Component, Fragment} from "react";
const CHI_PhotoLink = 'https://chicago-footage.net/members/mdl_phts/photos/'
const CHI_Link = 'https://chicago-footage.net/public_photos/'

class Memberphotos extends Component {
    render() {
        console.log(this.props.info.Photo_Count)
        let photocount = this.props.info.Photo_Count
        let model = this.props.info.Model
        let set = this.props.info.Photo_Set
        let site = this.props.info.Site
        let pics = [], thumbnails = []

        for(let i=1; i < photocount; i++) {
            let sequence = `${i}`.padStart(photocount.length, '0')
            if (site == "Chi Guys Feet") {
                pics.push(`${CHI_PhotoLink}${model}/${set}/good/${model}${set}${sequence}_fullsize.jpg`)
                thumbnails.push(`${CHI_Link}${model}/thumbnails/${model}${set}${sequence}_thumbnail.jpg`)
            } else {
                pics.push(`${CHI_PhotoLink}${model}/${set}/cz/${model}${set}${sequence}_fullsize.jpg`)
                thumbnails.push(`${CHI_Link}${model}/thumbnails/cz/${model}${set}${sequence}_thumbnail.jpg`)
            }
            
        }
        return (
            <div className="thumbnail_container">
                {thumbnails.map((pic,i) => (
                    <a href={pics[i]}>
                    <img className="generated" alt="" src={pic} key={pic}></img>
                    </a>
                ))}
            </div>
        )
    }
}

export default Memberphotos