import React, {Fragment, Component} from "react";
import Figure from 'react-bootstrap/Figure'
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';

const CHI_API = 'https://chicago-footage.net/preview_thumbnails/'

class InfoCard extends Component {
    render () {
        let imgSrc = `${CHI_API}${this.props.modelInfo.CF_Image}.jpg`
        //console.log(this.props.modelInfo)

        //console.log(this.props)

        if (this.props.modelInfo.Collaborator === '1') {

            return (
                <Fragment>
                    <br></br>
                    <div className="ml">
                      <ListGroup horizontal>
                      <ListGroup.Item>
                            <Figure.Image
                            variant="bottom" src={imgSrc}
                            />
                      </ListGroup.Item>
        
                      <ListGroup.Item>
                            <Figure.Caption>
                                <h3>{`${this.props.modelInfo.Model_Name}`}</h3>
                                <p>{`collaborator`}</p>
                                <ul>
                                <li>{`Shoe Size: ${this.props.modelInfo.Shoe_Size} U.S`}</li>
                                <li>{`Age: ${this.props.modelInfo.Age}`}</li>
                                <li>{`Height: ${this.props.modelInfo.Height} inches`}</li>
                                <li>{`Weight: ${this.props.modelInfo.Weight} pounds`}</li>
                                <li>{`Nationality: ${this.props.modelInfo.Nationality}`}</li>

                                {this.props.modelInfo.Website.length > 0 &&
                                    <li>{`Website: ${this.props.modelInfo.Website}`}</li>
                                }
                                {this.props.modelInfo.Blog.length > 0 &&
                                    <li>{`Blog: ${this.props.modelInfo.Blog}`}</li>
                                }
                                {this.props.modelInfo.Twitter.length > 0 &&
                                    <li>{`Twitter: ${this.props.modelInfo.Twitter}`}</li>
                                }
                                {this.props.modelInfo.Instagram.length > 0 &&
                                    <li>{`Instagram: ${this.props.modelInfo.Instagram}`}</li>
                                }
                                {this.props.modelInfo.TikTok.length > 0 &&
                                    <li>{`TikTok: ${this.props.modelInfo.TikTok}`}</li>
                                }
                                {this.props.modelInfo.OnlyFans.length > 0 &&
                                    <li>{`OnlyFans: ${this.props.modelInfo.OnlyFans}`}</li>
                                }
                                
                                
                                
                                </ul>
                                </Figure.Caption>
                      </ListGroup.Item>
                    </ListGroup>
                    </div>
                </Fragment>
                )
        } else {
        return (
        <Fragment>
            <br></br>
            <div className="ml">
              <ListGroup horizontal>
              <ListGroup.Item>
                    <Figure.Image
                    variant="bottom" src={imgSrc}
                    />
              </ListGroup.Item>
              <ListGroup.Item>
                    <Figure.Caption>
                        <h3>{`${this.props.modelInfo.Model_Name}`}</h3>
                        <ul>
                        <li>{`Shoe Size: ${this.props.modelInfo.Shoe_Size}`}</li>
                        <li>{`Age: ${this.props.modelInfo.Age}`}</li>
                        <li>{`Height: ${this.props.modelInfo.Height}`}</li>
                        <li>{`Weight: ${this.props.modelInfo.Weight}`}</li>
                        <li>{`Nationality: ${this.props.modelInfo.Nationality}`}</li>
                        </ul>
                        </Figure.Caption>
              </ListGroup.Item>
            </ListGroup>
            </div>
        </Fragment>
        )
    }
    }
}

export default InfoCard