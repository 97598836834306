import React, {Fragment, Component} from "react";
import Card from "./Card";

const Colodex = ({rosterData}) => {
    console.log(rosterData)
    return (
        <div className="Midiadex-cards">
            {
            rosterData.map((guy, i) => {
                if (rosterData[i].Collaborator === '1') {
                    return <Card key={i} page='mempage' profile_pic={rosterData[i].CF_Image} 
                    pageNumber={rosterData[i].CF_Members_Page_}
                    name={rosterData[i].Model_Name}
                    ></Card>
                }
            })
        }
        </div>
    )
}

export default Colodex