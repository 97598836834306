import React, {Component} from "react";
import Data from '../../JSON/updates_info.json'
import Mediacard from "./Mediacard";

class Mediadex extends Component {
    constructor() {
        super()
        this.state = { data: []}
    }

    componentDidMount () {
        fetch('https://chicago-footage.net/json/updates_info.json')
        .then(response => {
            return response.json()
        })
        .then (updateData => {
            console.log(updateData)
            this.setState({data: updateData})
        });
        
    }

    render() {
        return (
            <div className="Mediadex">
                <h1 className="section">Members Area Latest Updates</h1>
                <div className="Mediadex-cards">
                    
                    {this.state.data.map((media) => (
                        <Mediacard img={media.Image} prod={media.Product_Name} date={media.Date} 
                        type={media.Media_Type} link={media.URL} setNumber={media.Set}></Mediacard>
                    ))}
                </div>
            </div>
            
        )
    }
}

export default Mediadex