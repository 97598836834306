import React, {useState, useCallback} from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";

const CHI_PhotoLink = 'https://chicago-footage.net/members/mdl_phts/photos/'
const CHI_Link = 'https://chicago-footage.net/public_photos/'

let MemTest = (props) => {
  let photocount = props.info.setData.Photo_Count
  let model = props.info.setData.Model
  let set = props.info.setData.Photo_Set
  let site = props.info.setData.Site
  let pics = [], thumbnails = [], phts = []

  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
  setCurrentImage(index);
  setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
  setCurrentImage(0);
  setViewerIsOpen(false);
  };

  for(let i=1; i < photocount; i++) {
      let sequence = `${i}`.padStart(photocount.length, '0')
      if (site == "Chi Guys Feet") {
          pics.push(`${CHI_PhotoLink}${model}/${set}/good/${model}${set}${sequence}_fullsize.jpg`)
          thumbnails.push({
              src: `${CHI_Link}${model}/thumbnails/${model}${set}${sequence}_thumbnail.jpg`
          })
          phts.push({
              src: `${CHI_PhotoLink}${model}/${set}/good/${model}${set}${sequence}_fullsize.jpg`,
              sizes: ["(min-width: 480px) 50vw,(min-width: 1024px) 33.3vw,100vw"],
              width: 4,
              height: 3
          })
      } else {
          pics.push(`${CHI_PhotoLink}${model}/${set}/cz/${model}${set}${sequence}_fullsize.jpg`)
          thumbnails.push(`${CHI_Link}${model}/thumbnails/cz/${model}${set}${sequence}_thumbnail.jpg`)
      }
      
  }
  console.log(phts)
  return (
      <div>
  <Gallery photos={thumbnails} onClick={openLightbox} />
  <ModalGateway>
    {viewerIsOpen ? (
      <Modal onClose={closeLightbox}>
        <Carousel
          currentIndex={currentImage}
          views={phts.map(x => ({
            ...x,
            srcset: x.srcSet,
          }))}
        />
      </Modal>
    ) : null}
  </ModalGateway>
</div>
  )
}

export default MemTest