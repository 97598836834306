import React, {Component, Fragment} from "react";
import { Link } from "react-router-dom";
import '../../Videoscreen.css'

class Videoscreen extends Component {
    render () {
        const product = this.props.title.split(' ').join('').toLowerCase()
        let srcLink = `https:${this.props.jw_link}`
        //let downloadLink = 'https://chicago-footage.net/members/DLDS/'+product+'.mp4'
        let downloadLink = 'https://chicago-footage.net/members/download.php?download_file='+product+'.mp4'
       
        return (
            <Fragment>
            <div className="row">
            <div className="player">
                <div className="video_container">
                    <iframe src={srcLink} width="960" height="540" 
                    frameborder="0" scrolling="auto" 
                    title={this.props.title} allowfullscreen
                    ></iframe>
                </div>
                </div>
                </div>
                <div className="video_detail">
                    <ul>
                    {<li><h4 class="player__title"> {this.props.title} </h4></li>}
                    <li>Video Length: {this.props.durration} | Date Posted: {this.props.post_date}</li>
                    <li> <a href={downloadLink} >Download</a></li>
                    </ul>
                </div>
            </Fragment>
        )
    }
}

export default Videoscreen