import React, {Component} from "react";
const CHI_Link = 'https://chicago-footage.net/public_photos/'
const CHI_PhotoLink = 'https://chicago-footage.net/public_photos/'

class Previews extends Component {
    render() {

        let previews = [
            this.props.preview1,
            this.props.preview2,
            this.props.preview3
        ]

        let previewThumbnails = []
        let previewPics = []

        previews.map((photo) => {
            if (!this.props.site) return false
            let sequence = photo.padStart(this.props.count.length, '0')
            if (this.props.site == "Chi Guys Feet") {
                previewThumbnails.push(`${CHI_Link}${this.props.model}/thumbnails/${this.props.model}${this.props.set}${sequence}_thumbnail.jpg`)
            } else {
                previewThumbnails.push(`${CHI_Link}${this.props.model}/thumbnails/cz/${this.props.model}${this.props.set}${sequence}_thumbnail.jpg`)
            }
        })

        previews.map((photo) => {
            if (!this.props.site) return false
            let sequence = photo.padStart(this.props.count.length, '0')
            if (this.props.site == "Chi Guys Feet") {
                previewPics.push(`${CHI_PhotoLink}${this.props.model}/${this.props.model}${this.props.set}${sequence}_fullsize.jpg`)
            } else {
                previewPics.push(`${CHI_PhotoLink}${this.props.model}/cz/${this.props.model}${this.props.set}${sequence}_fullsize.jpg`)
            }
        })
        return (
            <div>
                <a href={previewPics[0]}>
                <img className="generated" src={previewThumbnails[0]}></img>
                </a>

                <a href={previewPics[1]}>
                <img className="generated" src={previewThumbnails[1]}></img>
                </a>

                <a href={previewPics[2]}>
                <img className="generated" src={previewThumbnails[2]}></img>
                </a>
                
            </div>
        )
    }
}
export default Previews