import React, {Fragment, Component} from "react";
import NaviLinksRoster from "../NaviLinksRoster";
//import rosterData from '../JSON/roster_info.json'
import Rosterdex from "../Rosterdex";
import "../Css/main.css"

// Google Analytics
import ReactGA from 'react-ga';

class Roster extends Component {
    constructor() {
        super()
        this.state = {
            //chiGuy: rosterData,
            chiGuy: [],
            searchfield: ''
        }
    }
    
    componentDidMount () {
        fetch('https://chicago-footage.net/json/roster_info.json')
        .then(response => {
            return response.json()
        })
        .then (rosterData => {
            console.log(rosterData)
            this.setState({chiGuy: rosterData})
        });
        
    }

    onSearchChange = (event) => {
        this.setState({searchfield: event.target.value})
    }

    render() {

        //const TRACKING_ID = 'G-DM4W28MF1G'
        const TRACKING_ID = 'UA-39507870-1'
        ReactGA.initialize(TRACKING_ID)
        ReactGA.pageview(document.location.pathname);

        console.log(this.state)
        const filterchiGuys = this.state.chiGuy.filter(chiGuy => {
            return chiGuy.Model_Name.toLowerCase().includes(this.state.searchfield.toLowerCase())
        })
        return(
            <div className="tc">
                <NaviLinksRoster onSearchChange={this.onSearchChange}></NaviLinksRoster>
                <Rosterdex rosterData={filterchiGuys}></Rosterdex>
            </div>
        )
    }
}

export default Roster