import React, {Component} from "react";
import Data from './JSON/marquee_info.json'
import Carousel from "react-bootstrap/Carousel";
import './Roundabout.css'
const CHI_API = 'https://chicago-footage.net/cfMarquee/images/photos/'
class Roundabout extends Component {
    /* static defaultProps = {
        Data
    } */
    constructor() {
        super()
        this.state = {data: []}
    }

    componentDidMount () {
            fetch('https://chicago-footage.net/json/marquee_info.json')
            .then(response => {
                return response.json()
            })
            .then (rosterData => {
                console.log(rosterData)
                this.setState({data: rosterData})
            });
    }

    render() {
        return (
            <div>
            <Carousel className="Roundabout">
                {this.state.data.map((marquee) => (
                    <Carousel.Item>
                        <img
                            className='d-block w-100'
                            src={`${CHI_API}${marquee.Image}_l.jpg`}
                            alt='First slide'
                        ></img>
                        <Carousel.Caption>
                            <h1 >{marquee.Header}</h1>
                            <a href={`${marquee.URL}`}>
                                {/*<Button variant='dark' size="lg">Check it Out</Button>*/}
                            </a>
                        </Carousel.Caption>
                    </Carousel.Item>
                ))}
            </Carousel>
            </div>
        )
    }
}
export default Roundabout