import React, {Fragment, Component} from "react";
import NaviContainer from '../Components/NaviContainer';
import rosterData from '../../JSON/roster_info.json'
import InfoCard from "../../InfoCard";
import Card from 'react-bootstrap/Card';
import Featureing from "../Components/Featureing";

// Google Analytics
import ReactGA from 'react-ga';

class Page extends Component {
    constructor() {
        super()
        this.state = { 
            data: []
         }
         this.getModelInfo = this.getModelInfo.bind(this)
    }

    componentDidMount () {
        fetch('https://chicago-footage.net/json/roster_info.json')
        .then(response => {
            return response.json()
        })
        .then (updateData => {
            console.log(updateData)
            this.setState({data: updateData})
        });
    }

    getModelInfo (num) {
        let i = 0;
        if (this.state.data[i] === undefined) return false
        while (this.state.data[i].CF_Members_Page_.split('=')[1] != num) {
            i++
        }
        return this.state.data[i]
    }

    render() {

        //const TRACKING_ID = 'G-DM4W28MF1G'
        const TRACKING_ID = 'UA-39507870-1'
        ReactGA.initialize(TRACKING_ID)
        ReactGA.pageview(document.location.pathname);

        let pageNumber = window.location.href.split('=')[1]
        let modelInfo = this.getModelInfo(pageNumber);

        if(modelInfo) {
            let model = modelInfo.Model_Name.toLocaleLowerCase()
            return (
                <Fragment>
                <NaviContainer></NaviContainer>
                <br />
                <Card bg="black" text="black" style={{width: '85%'}} className="test center">
                    <div className="center">
                    <InfoCard modelInfo={modelInfo} ></InfoCard>
                    </div>
                    <br />
                    <Featureing model={model} site='Chi Guys Feet'></Featureing>
                    <br />
                    <Featureing model={model} site='Chi Soles Exposed'></Featureing>
                    <br />
                    <Featureing model={model} site='Chi Zillas'></Featureing>
                </Card>
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <NaviContainer></NaviContainer>
                    <p>Loading...</p>
                </Fragment>
            )
        }
    }
}

export default Page