import React, {Component} from 'react';
import Nav from 'react-bootstrap/Nav';

class NaviLlinks extends Component {
    render() {
        return (
            <Nav.Link href={this.props.url}>{this.props.text}</Nav.Link>
        )
    }
}
export default NaviLlinks